import * as Sentry from '@sentry/sveltekit';
import { LOCAL_DEV } from './globals';
if (!LOCAL_DEV) {
	Sentry.init({
		dsn: 'https://3ecdef3da7ed421faa851b200fa5f4cc@o4505402295779328.ingest.sentry.io/4505403780104192',
		integrations: [
			new Sentry.BrowserTracing({
				// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					'localhost',
					/^https:\/\/dnbr\.to\/api/,
					/^https:\/\/dev.dnbr\.to\/api/
				]
			}),
			new Sentry.Replay()
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	});
} else {
	console.log('[LOCAL DEV] Sentry disabled in local dev mode');
}
let handler = Sentry.handleErrorWithSentry();
export const handleError = (...args) => {
	if (!LOCAL_DEV) {
		handler(...args);
	}
};
