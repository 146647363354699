import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')];

export const server_loads = [0];

export const dictionary = {
	"/": [~4],
	"/_dev/ui/auth": [17,[3]],
	"/(webflow)/about-new": [~5,[2]],
	"/billing": [~7],
	"/dash": [~8],
	"/dev-access": [9],
	"/e/[eventId]": [~10],
	"/maintenance": [11],
	"/new": [12],
	"/new/[...initial]": [13],
	"/(webflow)/pricing": [~6,[2]],
	"/support": [15],
	"/s/[id]": [~14],
	"/u": [16]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};